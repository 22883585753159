* {
  font-family: "Quattrocento", serif;
}

body {
  margin: 0;
  height: 100%;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main {
  position: absolute;
  top: 50px;
  bottom: 0;
  overflow-y: auto;
  width: 100%;
}

h1 {
  font-size: 50px;
  font-weight: normal;
  position: relative;
  overflow: hidden;
  margin-bottom: 8px;

}

p {
  font-size: 22px;
}

a {
  color: #a56cd4;
  text-decoration: none;
}

a:hover {
  color: #ba0c5f;
}

@media screen and (max-width: 768px) {
  p {
    font-size: 18px;
  }

  h1 {
    font-size: 35px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
