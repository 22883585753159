/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  margin-left: 60px;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 90px;
  background-color: black;
  color: #fff;
  font-size: 16px;
  text-align: center;
  padding: 5px 2px 2px 22px;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -100px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}
